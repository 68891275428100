import React from "react";
import Button from "../Button";

export default function HomeCardCheckMarks({
  routeCallback,
  icon,
  title,
  items,
  buttonText,
  route,
  onClick,
  getInfo,
  highlight,
}) {
  return (
    <div className="flex flex-col">
      <div className="p-3">
        {items.map((item, i) => {
          return (
            <div key={i} className="flex py-1 items-center">
              <img className="w-4 h-4" src={"checkmark.svg"} alt="Info" />
              <div className="px-2">{item}</div> <br />
            </div>
          );
        })}
      </div>
      <div className={"flex justify-center px-4" + (getInfo ? "" : " p-4")}>
        {buttonText && (
          <Button type="proceed" onClick={onClick} buttonText={buttonText} />
        )}
      </div>
      {getInfo && (
        <div className="flex justify-center p-4">
          {buttonText && (
            <Button type="info" onClick={getInfo} buttonText="Learn" />
          )}
        </div>
      )}
    </div>
  );
}
