import React from "react";
import { ethers, BigNumber } from "ethers";
import Utils from "../Utils";

import OptiBuyArtifact from "../../contracts/OptiBuy.json";

import Button from "../Button";

const optiBuyData = require("../../contracts/optibuy-data.json");

const zero = BigNumber.from("0");

export class OptiBuys extends React.Component {
  static optiComponentName = "OptiBuys";

  state = {
    info: false,
  };

  static loading = (address) => {
    return `Loading OptiBuy: ${address}`;
  };

  contribute = async (campaign, weibn) => {
    let { makeTransaction } = this.props;
    await makeTransaction(campaign.contract, "contribute", [], weibn);
  };

  finalize = async (campaign, token) => {
    let { makeTransaction } = this.props;
    let amountOut = Utils.getTokenOut(token, campaign.balance);
    const { provider } = this.props;
    const blockNumBefore = await provider.getBlockNumber();
    const blockBefore = await provider.getBlock(blockNumBefore);
    const timestamp = blockBefore.timestamp;
    const deadline = timestamp + 60;
    console.log(amountOut.tokenOut.toString(), deadline);
    await makeTransaction(campaign.contract, "finalize", [
      amountOut.tokenOut,
      deadline,
    ]);
  };

  static startingData = async (
    provider,
    signer,
    routeInfo,
    selectedAddress,
    ethBalance,
    safeGasPrice,
    utils,
  ) => {
    const { getToken } = utils;
    const { tokenAddress } = routeInfo;

    let data = { selectedAddress, campaigns: [], totalUserBalance: zero };
    let cachedCampaigns;
    if (tokenAddress === "") {
      cachedCampaigns = optiBuyData["topCampaigns"];
    } else {
      cachedCampaigns = optiBuyData[tokenAddress];
    }
    const gasCost = this.contributeGasCost(safeGasPrice);
    if (cachedCampaigns) {
      data = {
        ...data,
        gasCost: gasCost,
        token: await getToken(tokenAddress),
        campaigns: cachedCampaigns,
        initialized: false,
      };
      data = await this.populateCampaigns(
        data,
        ethBalance,
        provider,
        signer,
        selectedAddress,
        getToken,
      );
      data.initialized = true;
    } else {
      data = {
        selectedAddress,
        token: getToken(tokenAddress),
        campaigns: [],
        initialized: true,
      };
    }
    data.maximumAmount = ethBalance.sub(gasCost);
    return data;
  };

  withdraw = async (vault) => {
    let { makeTransaction } = this.props;
    await makeTransaction(vault.contract, "withdraw", []);
  };

  earlyWithdraw = async (vault) => {
    let { makeTransaction } = this.props;
    await makeTransaction(vault.contract, "earlyWithdraw", []);
  };

  static populateCampaigns = async (
    data,
    ethBalance,
    provider,
    signer,
    selectedAddress,
    getToken,
  ) => {
    const { token } = data;
    for (let campaign of data.campaigns) {
      const { address } = campaign;
      if (token) {
        campaign.token = token;
      } else {
        return { ...data, campaigns: [] };
      }

      //Populate / upconvert
      campaign.threshold = ethers.BigNumber.from(campaign.threshold);
      campaign.deadline = ethers.BigNumber.from(campaign.deadline);
      campaign.unlock = ethers.BigNumber.from(campaign.unlock);
      campaign.balance = ethers.BigNumber.from(campaign.balance);
      campaign.totalShares = ethers.BigNumber.from(campaign.totalShares);
      campaign.totalContribution = zero;
      campaign.tokenBalance = zero;
      campaign.userContribution = zero;
      campaign.userTokenBalance = zero;
      campaign.withdrawn = zero;
      campaign.progress = "0";

      campaign.contract = new ethers.Contract(
        address,
        OptiBuyArtifact.abi,
        signer,
      );

      await this.refreshCampaign(data, campaign, provider);
    }
    return data;
  };

  static contributeGasCost = (safeGasPrice) => {
    let v = BigNumber.from("85000")
      .mul(safeGasPrice)
      .mul(10 ** 9);
    return v;
  };

  static refreshCampaign = async (data, campaign, provider) => {
    let { selectedAddress, token } = data;
    let { balance, threshold, deadline, unlock, contract } = campaign;
    let timestamp = parseInt(Date.now() / 1000);
    campaign.locked = unlock.gt(timestamp);
    if (campaign.status === "FUNDING") {
      campaign.balance = await provider.getBalance(campaign.address);
      if (threshold.gt(0)) {
        campaign.progress = campaign.balance.mul(100).div(threshold).toString();
        if (balance.gt(threshold)) {
          campaign.progress = "100";
        }
      }
      if (selectedAddress) {
        campaign.contribution =
          await campaign.contract.contributionOf(selectedAddress);
      }
      if (deadline.lt(timestamp)) {
        campaign.status = "PENDING";
      }
    }

    if (campaign.status === "PENDING") {
      campaign.totalShares = await campaign.contract.totalShares();
      if (campaign.totalShares.gt(0)) {
        campaign.status = "FINALIZED";
      } else if (balance.lt(threshold)) {
        //campaign.status = "FAILED"
      }
    }
    if (campaign.status === "FINALIZED") {
      campaign.progress = "100";
      campaign.balance = campaign.totalShares;
      campaign.tokenBalance = await token.contract.balanceOf(contract.address);
      campaign.currentShares = await campaign.contract.currentShares();
      campaign.withdrawn = campaign.totalShares.sub(campaign.currentShares);

      if (selectedAddress) {
        campaign.userTokenBalance =
          await campaign.contract.tokenBalanceOf(selectedAddress);
      }
    }
  };

  static updateBalances = async (
    provider,
    data,
    selectedAddress,
    ethBalance,
    safeGasPrice,
    utils,
  ) => {
    if (!data || !data.campaigns) {
      console.log("PINEAPPLE 2 NO DATA");
      return data;
    }
    for (let campaign of data.campaigns) {
      this.refreshCampaign(data, campaign, provider);
    }
    return data;
  };

  static handleConnectedWallet = async (data, utils) => {
    let { token } = data;
    token = await utils.getToken(token.address, true);

    return { ...data, token };
  };

  render() {
    const { data, selectedAddress } = this.props;
    const { ethInput } = this.state;
    let { token, campaigns, selectedCampaign, maximumAmount } = data;

    return (
      <div className="md:px-10 md:w-2/3 text-white h-auto">
        <div className="flex flex-row items-center justify-between">
          <div className="md:w-1/2 p-2 text-left">
            <div className="text-3xl font-bold md:py-5">
              {token && token.name} OptiBuy
            </div>
            <div className="">
              The adrenaline-pumping group stake experience.
            </div>
          </div>
          {token && (
            <div className="w-full content-center items-center justify-center">
              <img alt="logo" className="h-32 w-32" src={token.logo} />
            </div>
          )}
        </div>

        <div className="md:flex py-3 text-center items-center justify-between">
          <div
            name="top-panel"
            className={
              "w-full absolute left-0 md:relative md:py-1 gap-y-6 flex flex-col " +
              (selectedCampaign ? "md:rounded-t-xl" : "md:rounded-xl")
            }
          >
            {campaigns.map((campaign) => {
              const {
                status,
                threshold,
                deadline,
                unlock,
                withdrawn,
                balance,
                tokenBalance,
                userTokenBalance,
                progress,
                locked,
                owner,
              } = campaign;
              const deadlineDisplay = Utils.timeRemaining(deadline);
              const unlockDisplay = Utils.timeRemaining(unlock);
              const balanceDisplay = Utils.ethDisplay(balance);
              let tokenBalanceDisplay = Utils.tokenDisplay(tokenBalance, token);
              let userTokenBalanceDisplay = Utils.tokenDisplay(
                userTokenBalance,
                token,
              );
              let goalDisplay = "";
              if (threshold.gt(0) && threshold.gt(balance)) {
                goalDisplay = Utils.ethDisplay(threshold) + " ETH minimum";
              }
              const withdrawalFeesPaid = withdrawn.div(4);
              const yieldEarned = withdrawalFeesPaid
                .mul(10000)
                .div(campaign.currentShares.sub(withdrawalFeesPaid));
              const yieldDisplay = (yieldEarned / 100).toFixed(2) + "%";
              return (
                <div
                  className={
                    "rounded-xl bg-gradient-to-bl p-2 from-blue-900 to-pink-900"
                  }
                >
                  <div className="rounded-xl bg-black flex flex-row items-center">
                    <div className=" font-bold text-2xl w-2/3 m-2 p-3">
                      {campaign.name}
                    </div>
                  </div>

                  <div className="p-3">
                    <div className="flex flex-row justify-between">
                      <div className="text-left font-bold">
                        {balanceDisplay} ETH RAISED
                      </div>
                      <div className="text-right">
                        {goalDisplay}
                        {status === "FINALIZED" &&
                          locked &&
                          `${yieldDisplay} reflection yield`}
                      </div>
                    </div>

                    <div className="w-full border-2 border-black content-center h-8 bg-red-500 rounded-r-full">
                      <div
                        className={
                          "h-full bg-green-500 text-center rounded-r-full"
                        }
                        style={{ width: progress + "%" }}
                      ></div>
                    </div>

                    <div className="flex flex-row justify-between">
                      <div className="text-left">
                        {status === "FUNDING" &&
                          `${deadlineDisplay} to contribute`}
                        {status === "FINALIZED" &&
                          `Holding ${tokenBalanceDisplay} ${token.symbol}!`}
                      </div>
                      <div className="text-right">
                        {status === "PENDING" && "Pending..."}
                        {status === "FAILED" && "Failed"}
                        {status !== "FAILED" &&
                          locked &&
                          `${unlockDisplay} to unlock`}
                        {!locked && `Unlocked`}
                      </div>
                    </div>
                  </div>
                  {selectedAddress && (
                    <div>
                      {status === "FUNDING" && (
                        <div className="p-3 md:flex flex-row gap-4">
                          <div className="md:w-1/2 flex flex-row bg-white text-black rounded focus:outline-none items-center p-2 mb-2">
                            <div
                              className="text-left underline cursor-pointer w-1/2"
                              onClick={() => {
                                this.setState({
                                  ethInput:
                                    ethers.utils.formatEther(maximumAmount),
                                });
                              }}
                            >
                              MAX
                            </div>
                            <input
                              className="text-right w-1/2"
                              type="number"
                              step="any"
                              name="ethIn"
                              required
                              value={ethInput}
                              placeholder="0"
                              onChange={(e) => {
                                this.setState({ ethInput: e.target.value });
                              }}
                            />
                          </div>
                          <Button
                            onClick={async () => {
                              await this.contribute(
                                campaign,
                                ethers.utils.parseEther(ethInput),
                              );
                            }}
                            buttonText={"Contribute ETH"}
                            type={"proceed"}
                          />
                        </div>
                      )}
                      {status === "FAILED" && (
                        <div className="p-3">
                          <Button
                            onClick={async () => {
                              await this.recover(campaign);
                            }}
                            buttonText={"Recover"}
                            type={"proceed"}
                          />
                        </div>
                      )}
                      {status === "PENDING" && selectedAddress === owner && (
                        <div className="p-3">
                          <Button
                            onClick={async () => {
                              await this.finalize(campaign, token);
                            }}
                            buttonText={"Finalize"}
                            type={"proceed"}
                          />
                        </div>
                      )}
                      {status === "FINALIZED" && (
                        <div className="p-3">
                          Your balance: {userTokenBalanceDisplay} {token.symbol}
                          {userTokenBalance.gt(0) && locked ? (
                            <Button
                              onClick={async () => {
                                await this.earlyWithdraw(campaign);
                              }}
                              buttonText={"Early Withdraw (20% tax!)"}
                              type={"proceed"}
                            />
                          ) : (
                            <Button
                              onClick={async () => {
                                await this.withdraw(campaign);
                              }}
                              disabled={userTokenBalance.eq(0)}
                              buttonText={"Withdraw"}
                              type={"proceed"}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default OptiBuys;
