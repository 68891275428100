import React from "react";
import { NetworkErrorMessage } from "./NetworkErrorMessage";

export function ConnectWallet({ connectWallet, networkError, dismiss }) {
  return (
    <div>
      <div>
        {/* Metamask network should be set to Localhost:8545. */}
        {networkError && (
          <NetworkErrorMessage message={networkError} dismiss={dismiss} />
        )}
      </div>
      <div className="inline-flex flex-col gap-6 items-center w-full">
        <button
          onClick={async () => {
            await dismiss();
            connectWallet();
          }}
          className="items-center justify-center flex text-sm text-black bg-lime-500 font-bold hover:bg-lime-200 rounded px-8 py-2"
        >
          Connect Wallet
        </button>
      </div>
    </div>
  );
}
