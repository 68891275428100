import React from "react";
import { ethers, BigNumber } from "ethers";
import Utils from "../Utils";
import ActiveLPArtifact from "../../contracts/ActiveLP.json";
import Button from "../Button";

const zero = BigNumber.from("0");

export class ActiveLP extends React.Component {
  static optiComponentName = "ActiveLP";

  state = {
    info: false,
    newManager: "",
    set_triggerSize: zero,
    set_buySize: 0n,
    set_sellSize: 0n,
    set_cooldown: 0n,
  };

  static loading = (address) => {
    return `Loading ActiveLP: ${address}`;
  };

  finalize = async (campaign, token) => {
    let { makeTransaction } = this.props;
    let amountOut = await Utils.getTokenOut(token, campaign.balance);
    const { provider } = this.props;
    const blockNumBefore = await provider.getBlockNumber();
    const blockBefore = await provider.getBlock(blockNumBefore);
    const timestamp = blockBefore.timestamp;
    const deadline = timestamp + 60;
    console.log(amountOut.tokenOut.toString(), deadline);
    await makeTransaction(campaign.contract, "finalize", [
      amountOut.tokenOut,
      deadline,
    ]);
  };

  static startingData = async (
    provider,
    signer,
    routeInfo,
    selectedAddress,
    ethBalance,
    safeGasPrice,
    utils,
  ) => {
    const { getToken } = utils;
    const { tokenAddress, detailAddress } = routeInfo;

    let contract = new ethers.Contract(
      detailAddress,
      ActiveLPArtifact.abi,
      signer,
    );
    let token = await getToken(tokenAddress);
    let owner = await contract.owner();
    let triggerSize = await contract.triggerSize();
    let lastSwap = await contract.lastSwap();
    let buySize = await contract.buySize();
    let sellSize = await contract.sellSize();
    let cooldown = await contract.cooldown();
    let ethReserves = await contract.ethReserves();

    let weth = await getToken("0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2");

    let wethBalance = await weth.contract.balanceOf(owner);
    let tokenBalance = await token.contract.balanceOf(owner);

    let wethApproval = await weth.contract.allowance(owner, detailAddress);
    let tokenApproval = await token.contract.allowance(owner, detailAddress);

    const blockNumBefore = await provider.getBlockNumber();
    const blockBefore = await provider.getBlock(blockNumBefore);
    const timestamp = blockBefore.timestamp;

    let data = {
      selectedAddress,
      contract,
      wethBalance,
      tokenBalance,
      wethApproval,
      tokenApproval,
      triggerSize,
      lastSwap,
      buySize,
      sellSize,
      cooldown,
      ethReserves,
      timestamp,
      token,
      weth,
    };
    return data;
  };

  async approveToken() {
    let { data, makeTransaction } = this.props;
    let { token, contract } = data;
    const amount = ethers.constants.MaxUint256;
    await makeTransaction(token.contract, "approve", [
      contract.address,
      amount,
    ]);
  }

  async approveWeth() {
    let { data, makeTransaction } = this.props;
    let { weth, contract } = data;
    const amount = ethers.constants.MaxUint256;
    await makeTransaction(weth.contract, "approve", [contract.address, amount]);
  }

  async addManager(newManager) {
    let { data, makeTransaction } = this.props;
    let { contract } = data;
    console.log(newManager);
    await makeTransaction(contract, "setManager", [newManager]);
  }

  async getDeadline() {
    const { provider } = this.props;
    const blockNumBefore = await provider.getBlockNumber();
    const blockBefore = await provider.getBlock(blockNumBefore);
    const timestamp = blockBefore.timestamp;
    return timestamp + 5 * 60;
  }

  async buyToken() {
    let { data, makeTransaction } = this.props;
    let { contract, buySize, token } = data;
    let { tokenReserves, ethReserves } = token.pair;
    let amountInWithFee = buySize.mul(997);
    let numerator = amountInWithFee.mul(tokenReserves);
    let denominator = ethReserves.mul(1000).add(amountInWithFee);
    let tokenOut = numerator.div(denominator).mul(98).div(100);

    let deadline = await this.getDeadline();
    console.log(tokenOut.toString(), deadline);

    await makeTransaction(contract, "buyToken", [tokenOut, deadline]);
  }

  async sellToken() {
    let { data, makeTransaction } = this.props;
    let { contract, sellSize, token } = data;
    let { tokenReserves, ethReserves } = token.pair;

    let taxedTokenAmount = sellSize.mul(98).div(100);
    let tokenInWithFee = taxedTokenAmount.mul(997);
    let numerator2 = tokenInWithFee.mul(ethReserves);
    let denominator2 = tokenReserves.mul(1000n).add(tokenInWithFee);
    let ethOut = numerator2.div(denominator2);

    let deadline = await this.getDeadline();
    console.log(ethOut.toString(), deadline);

    await makeTransaction(contract, "sellToken", [ethOut, deadline]);
  }

  static handleConnectedWallet = async (data, utils) => {
    let { token } = data;
    token = await utils.getToken(token.address, true);

    return { ...data, token };
  };

  render() {
    const { data } = this.props;
    const { newManager } = this.state;
    let {
      token,
      wethApproval,
      tokenApproval,
      triggerSize,
      lastSwap,
      buySize,
      sellSize,
      cooldown,
      ethReserves,
      timestamp,
      wethBalance,
      tokenBalance,
    } = data;

    let tokenBalanceToWeth = ethers.BigNumber.from(
      (
        (tokenBalance * token.pair.ethReserves) /
        token.pair.tokenReserves
      ).toString(),
    );

    const ratio =
      tokenBalanceToWeth.toString() /
      tokenBalanceToWeth.add(wethBalance).toString();

    console.log(ratio);

    let canBuy = false;
    let canSell = false;

    const canTradeTime = lastSwap.add(cooldown);
    const canTrade = canTradeTime.lt(timestamp);

    let countdownDisplay = Utils.timeRemaining(canTradeTime);

    if (canTrade) {
      countdownDisplay = "Trade now";
      if (ratio > 0.75) {
        canSell = true;
      } else {
        canBuy = true;
      }
    }

    let changeDisplay = "n/a";

    if (!ethReserves.isZero()) {
      if (token.pair.ethReserves.gt(ethReserves)) {
        changeDisplay =
          "+" +
          Utils.ethDisplay(token.pair.ethReserves.sub(ethReserves)) +
          " ETH";

        if (token.pair.ethReserves > ethReserves.add(triggerSize)) {
          canSell = true;
        }
      } else {
        changeDisplay =
          "-" +
          Utils.ethDisplay(ethReserves.sub(token.pair.ethReserves)) +
          " ETH";
        if (token.pair.ethReserves < ethReserves.sub(triggerSize)) {
          canBuy = true;
        }
      }
    }

    return (
      <div className="md:px-10 md:w-2/3 text-white h-auto">
        <div className="flex flex-row items-center justify-between">
          <div className="md:w-1/2 p-2 text-left">
            <div className="text-3xl font-bold md:py-5">
              {token && token.name} ActiveLP
            </div>
            <div className="">Ultimate battlefield dominance.</div>
          </div>
          {token && (
            <div className="w-full content-center items-center justify-center">
              <img alt="logo" className="h-32 w-32" src={token.logo} />
            </div>
          )}
        </div>
        <div
          className={
            "rounded-xl bg-gradient-to-bl p-2 from-blue-900 to-pink-900"
          }
        >
          <table>
            <tr>
              <td>BuySize</td> <td>{Utils.ethDisplay(buySize)} ETH</td>
            </tr>
            <tr>
              <td>SellSize</td>{" "}
              <td>
                {Utils.tokenDisplay(sellSize, token)} {token.symbol}
              </td>
            </tr>
            <tr>
              <td>Countdown</td> <td>{countdownDisplay}</td>
            </tr>
            <tr>Balances</tr>
            <tr>
              <td>WETH</td> <td>{Utils.ethDisplay(wethBalance)}</td>
            </tr>
            <tr>
              <td>{token.symbol}</td>{" "}
              <td>{Utils.tokenDisplay(tokenBalance, token)}</td>
            </tr>
            <tr>
              <td>Ratio: </td> <td>{ratio}</td>
            </tr>
            <tr>
              <td>Target Ratio: </td> <td>.75</td>
            </tr>
            <tr></tr>
            <tr>
              <td>Pool change</td> <td>{changeDisplay}</td>
            </tr>
            <tr>
              <td>Trigger Size:</td>{" "}
              <td>+/- {Utils.ethDisplay(triggerSize)} ETH</td>
            </tr>
          </table>

          {tokenApproval.eq(0) && (
            <Button
              onClick={async () => {
                await this.approveToken();
              }}
              buttonText={`Approve ${token.symbol}`}
              type={"proceed"}
            />
          )}
          {wethApproval.eq(0) && (
            <Button
              onClick={async () => {
                await this.approveWeth();
              }}
              buttonText={`Approve WETH`}
              type={"proceed"}
            />
          )}

          <div className="w-full text-center">
            {!canBuy && !canSell && "NO TRADE ACTION"}
            {canBuy && (
              <Button
                onClick={async () => {
                  await this.buyToken();
                }}
                buttonText={"Buy"}
                type={"proceed"}
              />
            )}
            {canSell && (
              <Button
                onClick={async () => {
                  await this.sellToken();
                }}
                buttonText={"Sell"}
                type={"proceed"}
              />
            )}
          </div>
        </div>

        <div
          className={
            "rounded-xl bg-gradient-to-bl p-2 from-blue-900 to-pink-900"
          }
        >
          Management
          <div className="p-3 md:flex flex-row gap-4">
            <div className="md:w-1/2 flex flex-row bg-white text-black rounded focus:outline-none items-center p-2 mb-2">
              <input
                className="text-right"
                type="text"
                name="newManager"
                required
                value={newManager}
                placeholder="0x"
                onChange={(e) => {
                  this.setState({ newManager: e.target.value });
                }}
              />
            </div>
            <Button
              onClick={async () => {
                await this.addManager(newManager);
              }}
              buttonText={"Add manager"}
              type={"proceed"}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ActiveLP;
