import React from "react";
export default function HomeCardTitle({ icon, title, enabled = true }) {
  return (
    <div className="p-4 flex flex-row">
      <img
        className={"w-10 h-10 " + (enabled ? "" : "fill-gray-400")}
        src={icon}
        alt={title}
      />
      <div
        className={
          "px-2 font-bold text-3xl " +
          (enabled ? "text-blue-400" : "text-gray-400")
        }
      >
        {title}
      </div>
    </div>
  );
}
