import React from "react";
import Utils from "./Utils";

import Home from "./Home/Home";
import Dashboard from "./Dashboard/Dashboard";
import { OptiSwap } from "./OptiSwap/OptiSwap";
import { BonusSwap } from "./BonusSwap/BonusSwap";
import { OptiBuys } from "./OptiBuys/OptiBuys";
import { GroupLP } from "./GroupLP/GroupLP";
import { ActiveLP } from "./ActiveLP/ActiveLP";

import { ethers, BigNumber } from "ethers";
import "bootstrap/dist/css/bootstrap.css";

//ABIs
import erc20ABI from "../contracts/erc20.json";
import pairArtifact from "../contracts/pair.json";

//Provider stuff
import { SafeAppWeb3Modal } from "@gnosis.pm/safe-apps-web3modal";
import WalletConnectProvider from "@walletconnect/ethereum-provider";
import axios from "axios"; // move into utils

const uniswapV2FactoryABI = require("../contracts/uniswapV2Factory.json");
//move into address_constants
const uniswapV2FactoryAddress = "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f";
const WETHAddress = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";

const supportedTokens = require("../contracts/supported-tokens.json");
const nameMap = require("../contracts/name-map.json");

const zero = ethers.BigNumber.from(0);

let tokens = {};

class OptiRoute extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      info: false,
      log: [],
      selectedAddress: undefined,
      initialized: false,
      ethBalance: zero,
      txBeingSent: undefined,
      transactionError: undefined,
      networkError: undefined,
      route: "/",
      data: {},
      safeGasPrice: 50,
      loading: true,
      userDashboard: false,
    };
    this.state = this.initialState;
  }

  componentDidMount = async () => {
    this._provider = new ethers.providers.InfuraProvider(
      "homestead",
      "5862d42b474b40209c7fe142fbde9239",
    );
    this.uniswapV2Factory = new ethers.Contract(
      uniswapV2FactoryAddress,
      uniswapV2FactoryABI,
      this.getSigner(),
    );
    let route = window.location.hash;
    let tokenAddress;
    let detailAddress;
    let routeList = route.split("/");
    if (routeList.length > 1) {
      let originalRouteToken = routeList[1];

      if (supportedTokens[originalRouteToken]) {
        tokenAddress = originalRouteToken;
      } else if (nameMap[originalRouteToken.toLowerCase()]) {
        tokenAddress = nameMap[originalRouteToken.toLowerCase()];
        routeList[1] = tokenAddress;
      }

      if (routeList.length > 2) {
        detailAddress = routeList[2];
      }
      route = routeList.join("/");
    }

    if (window.ethereum) {
      try {
        // Request metamask to switch to the specified network
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: ethers.utils.hexValue(1) }],
        });
      } catch (error) {
        console.error("Failed to switch network:", error);
      }
    }

    if (tokenAddress) {
      await this.tokenSelectionRouteCallback(
        route,
        tokenAddress,
        detailAddress,
      );
    } else {
      this.setState({ loading: false });
    }
  };

  getToken = async (tokenAddress, refresh = false) => {
    let t = tokens[tokenAddress];
    if (t && !refresh) {
      return t;
    } else {
      let signer = this.getSigner();
      let token_contract = new ethers.Contract(tokenAddress, erc20ABI, signer);
      let token = {};
      if (supportedTokens[tokenAddress]) {
        token = supportedTokens[tokenAddress];
      } else {
        token = {
          symbol: await token_contract.symbol(),
          decimals: await token_contract.decimals(),
          name: await token_contract.name(),
          pairAddress: await this.uniswapV2Factory.getPair(
            tokenAddress,
            WETHAddress,
          ),
          tax: "0",
          logo: undefined,
        };
      }
      token.address = tokenAddress;
      token.contract = token_contract;
      token.balance = zero;
      if (token.pairAddress === "0x0000000000000000000000000000000000000000")
        return token;
      let pair_contract = new ethers.Contract(
        token.pairAddress,
        pairArtifact.abi,
        signer,
      );
      token.pair = {
        contract: pair_contract,
        ethReserves: zero,
        tokenReserves: zero,
        token0: await pair_contract.token0(),
      };
      tokens[tokenAddress] = token;
      await this.updateReserves(tokenAddress);
      return tokens[tokenAddress];
    }
  };

  updateReserves = async (tokenAddress) => {
    let token = await this.getToken(tokenAddress);
    let pair = token.pair;
    let ethReserves, tokenReserves;
    const reserves = await pair.contract.getReserves();
    if (pair.token0 === WETHAddress) {
      [ethReserves, tokenReserves] = reserves;
    } else {
      [tokenReserves, ethReserves] = reserves;
    }
    token.pair.ethReserves = ethReserves;
    token.pair.tokenReserves = tokenReserves;
    tokens[tokenAddress] = token;
  };

  setData = async (data, callback) => {
    if (callback) {
      this.setState({ data }, callback);
    } else {
      this.setState({ data });
    }
  };

  getSigner = () => {
    const { selectedAddress } = this.state;
    if (selectedAddress) {
      return this._provider.getSigner(0);
    }
    return new ethers.VoidSigner(
      "0x0000000000000000000000000000000000000000",
      this._provider,
    );
  };

  _initializeEthers = async () => {
    this._stopPollingData();

    const { route } = this.state;
    const web3Modal = new SafeAppWeb3Modal({
      network: "mainnet",
      cacheProvider: true,
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            projectId: "e23ae8d4ccb0f3f70a9e44810d40e98a",
            chains: [1],
            showQrModal: true,
            infuraId: "5862d42b474b40209c7fe142fbde9239",
          },
        },
      },
    });

    const instance = await web3Modal.requestProvider();
    this._provider = new ethers.providers.Web3Provider(instance);

    const signer = this._provider.getSigner();
    const address = await signer.getAddress();

    instance.on("accountsChanged", async ([newAddress]) => {
      newAddress = ethers.utils.getAddress(newAddress);
      const newEthBalance = await this._provider.getBalance(newAddress);
      this.setState(
        { selectedAddress: newAddress, ethBalance: newEthBalance },
        async () => {
          await this.tokenSelectionRouteCallback(
            ...this.state.lastRouteSelection,
          );
        },
      );
    });

    const ethBalance = await this._provider.getBalance(address);
    // TODO: Reload active component here for data
    // Including checking the route if needed for the component to process
    // by refactoring tokenSelectionRouteCallback into: reinitializeEthereumStateWithPathAndAddress
    // passing in URL path, address of user logged in, to Component.startingData(path, address, provider..)
    // with Component.startingData parsing the route. This includes refactoring tokenSelectionRouteCallback
    // into calling Component.startingData(path, selectedAddress) and filtering the #swap/ there
    let { Component } = this._getComponentFromRoute(route);
    if (Component.optiComponentName === "Home") {
      this.setState({
        initialized: true,
        selectedAddress: address,
        ethBalance,
      });
    } else {
      this.setState(
        {
          initialized: true,
          selectedAddress: address,
          loadingDisplay: "Loading",
          ethBalance,
        },
        async () => {
          let { data } = this.state;
          data = await Component.handleConnectedWallet(data, this.utils());
          this.setState({ data });
        },
      );
    }

    // In the future, this can be extracted to a gas service.
    axios
      .get(
        "https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=4493VQY6KMG7CK7K37UYZP4ASA22RBD2AN",
      )
      .then((res) => {
        // handle success
        window.res = res;
        if (res.data.result.SafeGasPrice) {
          this.setState({ safeGasPrice: res.data.result.SafeGasPrice });
        } else {
          // some sensible default
          this.setState({ safeGasPrice: 70 });
        }
      })
      .catch((error) => {
        this.setState({ safeGasPrice: 70 });
        console.log(error);
      });

    await this._updateBalances();
    this._startPollingData();
  };

  _startPollingData = () => {
    this._pollDataInterval = setInterval(() => this._updateBalances(), 30000);
  };

  _stopPollingData = () => {
    clearInterval(this._pollDataInterval);
    this._pollDataInterval = undefined;
  };

  _getComponentFromRoute = (route) => {
    let routeList = route.split("/");
    let routeRoot = routeList[0];
    let extra = routeList.length > 1 ? routeList[1] : false;
    let componentList = {
      "": Home,
      "#activelp": ActiveLP,
      "#pool": GroupLP,
      "#pump": OptiBuys,
      "#bonus": BonusSwap,
      "#swap": OptiSwap,
    };
    return { Component: componentList[routeRoot], extra: extra };
  };

  getActiveComponent = () => {
    const { route } = this.state;
    if (this.state.userDashboard) {
      console.log(this.state.selectedAddress);
      return { Component: OptiBuys, extra: "" };
    }
    let { Component, extra } = this._getComponentFromRoute(route);
    if (Component === Home) {
      window.route = route;
    }
    return { Component: Component, extra: extra };
  };

  utils = () => {
    return {
      emptyToken: {
        name: "hoge.finance",
        symbol: "HOGE",
        decimals: "9",
        address: "0xfAd45E47083e4607302aa43c65fB3106F1cd7607",
        pair: "0x7FD1de95FC975fbBD8be260525758549eC477960",
        logo: "hoge.png",
        tax: "2",
        balance: BigNumber.from(0),
      },
      getToken: this.getToken,
      getTokenOut: Utils.getTokenOut,
      getEthOut: Utils.getEthOut,
      updateReserves: this.updateReserves,
      quickBN: Utils.quickBN,
      quickZero: Utils.quickZero,
      ethDisplay: Utils.ethDisplay,
      tokenDisplay: Utils.tokenDisplay,
      timeRemaining: Utils.timeRemaining,
      nav: this.tokenSelectionRouteCallback,
    };
  };

  _updateBalances = async () => {
    let { loadingDisplay, data, selectedAddress, routeInfo, safeGasPrice } =
      this.state; //data is component specific data
    let ethBalance = BigNumber.from(0);
    if (selectedAddress) {
      ethBalance = await this._provider.getBalance(selectedAddress);
    }
    const { Component } = this.getActiveComponent();

    let signer = this.getSigner();

    if (loadingDisplay === "Loading") {
      data = await Component.startingData(
        this._provider,
        signer,
        routeInfo,
        selectedAddress,
        ethBalance,
        safeGasPrice,
        this.utils(),
      );
      this.setState({
        ethBalance,
        componentName: Component.optiComponentName,
        data,
        loadingDisplay: false,
      });
    } else if (data && data.initialized) {
      data = await Component.updateBalances(
        this._provider,
        data,
        selectedAddress,
        ethBalance,
        safeGasPrice,
        this.utils(),
      );
      this.setState({
        ethBalance,
        componentName: Component.optiComponentName,
        data,
        loadingDisplay: false,
      });
    } else {
      this.setState({ ethBalance, componentName: Component.optiComponentName });
    }
  };

  _setTransaction = async (tx, callback) => {
    if (callback) {
      this.setState({ txBeingSent: tx }, await callback());
    } else {
      this.setState({ txBeingSent: tx });
    }
  };

  makeTransaction = async (contract, func, args, value) => {
    try {
      let tx;
      if (value) {
        tx = await contract[func](...args, { value: value });
      } else {
        tx = await contract[func](...args);
      }
      this.setState({ txBeingSent: tx.hash, transactionError: undefined });
      const receipt = await tx.wait();
      if (receipt.status === 0) {
        throw new Error("Transaction failed");
      }
      this.setState(
        { txBeingSent: undefined, transactionError: undefined },
        () => {
          this._updateBalances();
        },
      );
    } catch (error) {
      if (error.code === "ACTION_REJECTED" || error.code === 4001) {
        this.setState(
          { txBeingSent: undefined, transactionError: undefined },
          () => {
            this._updateBalances();
          },
        );
        return;
      }
      this.setState({ transactionError: error, txBeingSent: undefined }, () => {
        this._updateBalances();
      });
    }
  };

  _setTransactionError = (txError) => {
    this.setState({ transactionError: txError });
  };

  _dismissTransaction = () => {
    this.setState({ txBeingSent: undefined });
  };

  _dismissTransactionError = async (callback) => {
    if (callback) {
      this.setState({ transactionError: undefined }, await callback());
    } else {
      this.setState({ transactionError: undefined });
    }
  };

  _dismissNetworkError = () => {
    console.log("dismissing in optiRoute");
    this.setState({ networkError: undefined });
  };

  routeCallback = async (path) => {
    const { selectedAddress, ethBalance, safeGasPrice } = this.state;
    if (path === "/") {
      const { Component } = this._getComponentFromRoute(path);
      window.location.hash = path;
      this.setState({
        route: path,
        data: {},
        componentName: Component.optiComponentName,
        loadingDisplay: null,
      });
      return;
    }

    this._stopPollingData();
    const { Component, extra } = this._getComponentFromRoute(path);

    this.setState({ loadingDisplay: Component.loading() }, async () => {
      let signer = this.getSigner();
      const data = await Component.startingData(
        this._provider,
        signer,
        path,
        selectedAddress,
        ethBalance,
        safeGasPrice,
        this.utils(),
      );
      window.location.hash = path;
      this.setState(
        {
          route: path,
          data: data,
          componentName: Component.optiComponentName,
          loadingDisplay: null,
          extra,
        },
        async () => {
          this._startPollingData();
        },
      );
    });
  };

  // Helper for navigating to a component from home page from a token selection.
  tokenSelectionRouteCallback = async (path, address, detailAddress) => {
    const { selectedAddress, ethBalance, safeGasPrice } = this.state;

    const { Component, extra } = this._getComponentFromRoute(path);
    this._stopPollingData();
    let routeInfo = { tokenAddress: address };
    if (detailAddress) {
      routeInfo["detailAddress"] = detailAddress;
    }
    this.setState(
      {
        lastRouteSelection: [path, address, detailAddress],
        routeInfo: routeInfo,
        loadingDisplay: Component.loading(address),
      },
      async () => {
        let signer = this.getSigner();
        const data = await Component.startingData(
          this._provider,
          signer,
          routeInfo,
          selectedAddress,
          ethBalance,
          safeGasPrice,
          this.utils(),
        );
        window.location.hash = path;
        this.setState(
          {
            loading: false,
            route: path,
            data: data,
            componentName: Component.optiComponentName,
            loadingDisplay: null,
            extra,
          },
          async () => {
            this._startPollingData();
          },
        );
      },
    );
  };

  getInfoCallback = async (path, address, detailAddress) => {
    const { selectedAddress, ethBalance, safeGasPrice } = this.state;

    const { Component, extra } = this._getComponentFromRoute(path);
    let routeInfo = {};

    let signer = this.getSigner();
    const data = await Component.startingData(
      this._provider,
      signer,
      routeInfo,
      selectedAddress,
      ethBalance,
      safeGasPrice,
      this.utils(),
    );
    this.setState(
      {
        lastRouteSelection: [path, address, detailAddress],
        loadingDisplay: Component.loading(address),
      },
      async () => {
        this.setState(
          {
            loading: false,
            route: path,
            data: data,
            componentName: "Info",
            loadingDisplay: null,
            extra,
          },
          async () => {
            this._startPollingData();
          },
        );
      },
    );
  };

  toggleDashboardCallback = async () => {
    this.setState({ userDashboard: !this.state.userDashboard });
  };

  selectedToken = async (address) => {
    let token = await this.getToken(address, true);
    this.setState({ token: token });
  };

  // This method checks if Metamask selected network is Localhost:8545
  _checkNetwork() {
    const netv = window.ethereum.networkVersion;
    if (netv === "1" || netv === 1) {
      return true;
    }

    this.setState({
      networkError: "Please connect Metamask to ETH MAINNET",
    });

    return false;
  }

  _log = (t) => {};

  render() {
    const {
      token,
      selectedAddress,
      networkError,
      transactionError,
      txBeingSent,
      initialized,
      ethBalance,
      data,
      safeGasPrice,
      loading,
    } = this.state;
    if (loading) {
      return (
        <div className="bg-gradient-to-bl from-blue-900 to-pink-900 flex flex-col h-auto" />
      );
    }
    let { Component } = this.getActiveComponent();
    return (
      <div className="bg-gradient-to-bl from-purple-500 to-pink-500 flex flex-col h-auto min-h-screen">
        <div className="md:justify-center">
          <Dashboard
            goHome={() => {
              this.routeCallback("/");
            }}
            transactionError={transactionError}
            txBeingSent={txBeingSent}
            initialized={initialized}
            selectedAddress={selectedAddress}
            networkError={networkError}
            ethBalance={ethBalance}
            connectWallet={this._initializeEthers}
            dismissNetworkError={this._dismissNetworkError}
            dismissTransactionError={this._dismissTransactionError}
            toggleDashboardCallback={this.toggleDashboardCallback}
          />
        </div>
        <div className="flex justify-center">
          <Component
            routeCallback={this.routeCallback}
            tokenSelectionRouteCallback={this.tokenSelectionRouteCallback}
            getInfoCallback={this.getInfoCallback}
            selectedToken={this.selectedToken}
            token={token}
            data={data}
            setData={this.setData}
            getToken={this.getToken}
            initialized={initialized}
            provider={this._provider}
            selectedAddress={selectedAddress}
            ethBalance={ethBalance}
            makeTransaction={this.makeTransaction}
            utils={this.utils()}
            safeGasPrice={safeGasPrice}
            txBeingSent={txBeingSent}
            signer={this.getSigner()}
            connectWallet={this._initializeEthers}
          />
        </div>
      </div>
    );
  }
}

export default OptiRoute;
