import React from "react";
import HomeCardBanner from "./HomeCardBanner";
import HomeCardSwap from "./HomeCardSwap";
import HomeCardBonusSwap from "./HomeCardBonusSwap";
import HomeCardGroupPool from "./HomeCardGroupPool";
import HomeCardOptiBuys from "./HomeCardOptiBuys";
import HomeCardLinks from "./HomeCardLinks";
import HomeCardOTCSwap from "./HomeCardOTCSwap";

export default class Home extends React.Component {
  static optiComponentName = "Home";
  static defaultData = () => {
    return {};
  };

  static updateBalances = async () => {};

  static loading = () => {
    return "Loading Home";
  };

  render() {
    const {
      token,
      routeCallback,
      getInfoCallback,
      tokenSelectionRouteCallback,
    } = this.props;
    return (
      <div className="justify-center md:w-2/3">
        <div className="">
          <HomeCardBanner />
        </div>
        <div className="p-3 gap-2 md:grid md:grid-cols-2 md:gap-5">
          {[
            HomeCardSwap,
            HomeCardBonusSwap,
            HomeCardOTCSwap,
            HomeCardGroupPool,
            HomeCardOptiBuys,
            HomeCardLinks,
          ].map((Card, i) => (
            <div
              key={i}
              className="md:w-100 mb-4 text-white bg-gray-800 rounded-xl opacity-70 hover:opacity-100 md:flex md:flex-col"
            >
              <Card
                tokenSelectionRouteCallback={tokenSelectionRouteCallback}
                token={token}
                routeCallback={routeCallback}
                getInfoCallback={getInfoCallback}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
