import React from "react";
import Select from "react-dropdown-select";
const supportedTokens = require("../contracts/supported-tokens.json");

const searchableTokens = Object.values(supportedTokens).map((t) => {
  t["search"] = t.name + t.symbol + t.address;
  return t;
});

export default function TokenSelect({ token, selectionCallback }) {
  return (
    <div className="font-bold text-white bg-black">
      <Select
        options={searchableTokens}
        searchable
        placeholder={token ? token.name : "Select a token..."}
        itemRenderer={({ item, itemIndex, props, state, methods }) => {
          return (
            <div
              onClick={() => methods.addItem(item)}
              className="py-2 items-center flex flex-row bg-black text-white"
            >
              <div className="hidden sm:block px-2">
                <img className="w-8 h-8" alt="logo" src={item.logo} />
              </div>
              <div className="px-2">{item.name}</div>
            </div>
          );
        }}
        searchBy={"search"}
        labelField={"name"}
        valueField={"address"}
        onChange={(value) => {
          if (selectionCallback) {
            selectionCallback(value[0]);
          }
        }}
      />
    </div>
  );
}
