import React from "react";
import { ConnectWallet } from "./ConnectWallet";
import Utils from "../Utils";

export default class ConnectedWallet extends React.Component {
  state = {
    info: false,
  };

  render() {
    const {
      initialized,
      selectedAddress,
      networkError,
      connectWallet,
      dismissTransactionError,
    } = this.props;
    const displayAddress = Utils.addressShortened(selectedAddress);
    return (
      <div className="">
        {initialized ? (
          <div className="inline-flex flex-col gap-6 items-center w-full">
            <div>
              Wallet connected:{" "}
              <a
                className="underline"
                target="_blank"
                rel="noreferrer"
                href={`https://etherscan.io/address/` + selectedAddress}
              >
                {displayAddress}
              </a>
            </div>
          </div>
        ) : (
          <ConnectWallet
            connectWallet={() => connectWallet()}
            networkError={networkError}
            dismiss={dismissTransactionError}
          />
        )}
      </div>
    );
  }
}
