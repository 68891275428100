import React from "react";

export default function HomeCardBanner() {
  return (
    <div className="md:flex flex-row">
      <div className="md:w-3/4 text-white px-3 md:py-5 ">
        <div className="text-3xl font-bold">Smart Contract Suite</div>
        <div className="py-3">Open-source R&D on Ethereum + ICP</div>
      </div>
      <div className="hidden sm:block">
        <img
          className="md:p-10 float-right"
          src={"art-blobby.svg"}
          alt="Blobby"
        />
      </div>
    </div>
  );
}
