import React from "react";
import SVG from "react-inlinesvg";
import { ethers, BigNumber } from "ethers";
import OptiSwapArtifact from "../../contracts/OptiSwap.json";
import TokenSelect from "../TokenSelect";
import "bootstrap/dist/css/bootstrap.css";
import Utils from "../Utils";
const optiSwap = require("../../contracts/opti-swap-address.json");
const zero = ethers.BigNumber.from(0);

export class OptiSwap extends React.Component {
  static optiComponentName = "OptiSwap";
  constructor(props) {
    super(props);
    this.initialState = {
      buying: true,
      tokenBalance: zero,
      tokenInput: "",
      ethInput: "",
      tokenIn: zero,
      ethBalance: zero,
      ethIn: zero,
      displaySettings: false,
      slippage: ".002",
      deadline: "10",
      gasCost: 0,
      orderRealizePercent: "0",
      optiSize: zero,
      gasPrice: 30,
      networkError: undefined,
      token: { symbol: "" },
      log: [],
    };
    this.counter = 0;
    this.state = this.initialState;
  }

  static loading = (address) => {
    return `Loading OptiSwap: ${address}`;
  };

  clickedOnToken = async (address) => {
    const { getToken } = this.props;
    let token = await getToken(address);
    this.setState({ token });
  };

  static optimize(data, utils) {
    let { token, gasCost, gasPrice, buying } = data;
    const { ethReserves, tokenReserves } = token.pair;
    if (ethReserves.isZero() || gasCost === 0) {
      return data;
    }
    let sqrt = (x) => {
      const ONE = ethers.BigNumber.from(1);
      const TWO = ethers.BigNumber.from(2);
      let z = x.add(ONE).div(TWO);
      let y = x;
      while (z.sub(y).isNegative()) {
        y = z;
        z = x.div(z).add(z).div(TWO);
      }
      return ethers.BigNumber.from(y);
    };

    const originalGasCost = gasCost;
    gasCost = this.totalCostEstimate(buying, gasPrice);
    gasCost = ethers.utils.parseUnits(gasCost.toString(), "gwei");

    if (!buying) {
      gasCost = gasCost.mul(tokenReserves).div(ethReserves);
    }
    const g = sqrt(gasCost);
    const x = buying ? ethReserves : tokenReserves;
    const opti = g.mul(x).div(sqrt(x).add(g));
    let orderRealizePercent;
    if (buying) {
      orderRealizePercent = Utils.getTokenOut(token, opti, originalGasCost)[
        "orderRealizePercent"
      ];
    } else {
      orderRealizePercent = Utils.getEthOut(token, opti, originalGasCost)[
        "orderRealizePercent"
      ];
    }
    data.optiSize = opti;
    data.optiPercent = orderRealizePercent;
    return data;
  }

  _useOptisize() {
    let { data, utils } = this.props;
    let { initialized, buying, token, gasCost } = data;
    data = OptiSwap.optimize(data, utils);
    let optiSize = data.optiSize;
    let { symbol, decimals } = token;
    if (!initialized) {
      this.log("Please connect to network to read liquidity pool data.");
      return;
    }
    if (optiSize.isZero()) {
      this.log("Waiting for pool data to calculate OptiSize.");
      return;
    }
    const display = buying
      ? Utils.ethDisplay(optiSize)
      : Utils.tokenDisplay(optiSize, token);

    this.log(
      `Gas cost estimate: ${Utils.ethDisplay(gasCost)} ETH, OptiSize ${display} ${buying ? "ETH" : symbol}`,
    );

    if (buying) {
      this.setState({
        ethIn: optiSize,
        ethInput: ethers.utils.formatEther(optiSize),
      });
    } else {
      this.setState({
        tokenIn: optiSize,
        tokenInput: ethers.utils.formatUnits(optiSize, decimals),
      });
    }
  }

  _formatPercent(numerator, denominator) {
    const p = numerator.mul(10000).div(denominator).toString().padStart(4, "0");
    return p.slice(0, 2) + "." + p.slice(2, 4);
  }

  // limit logs to 5 messages
  static concatLogs = (logs) => {
    return logs.length > 4 ? logs.slice(1, 5) : logs;
  };

  concatLogs = (logs) => {
    return OptiSwap.concatLogs(logs);
  };

  log = (newMessage) => {
    let { setData, data } = this.props;
    let { logs } = data;
    logs = this.concatLogs(logs);
    const newEntry = `> ${newMessage}`;
    const newLogs = logs.concat([newEntry]);
    setData({ ...data, logs: newLogs });
  };

  _resetAmounts() {
    const zero = ethers.BigNumber.from(0);
    this.setState({
      tokenIn: zero,
      tokenOut: zero,
    });
  }

  async getDeadline() {
    const { provider } = this.props;
    const blockNumBefore = await provider.getBlockNumber();
    const blockBefore = await provider.getBlock(blockNumBefore);
    const timestamp = blockBefore.timestamp;
    const deadline_seconds = this.state.deadline * 60;
    return timestamp + deadline_seconds;
  }

  _buyToken = async () => {
    let { data, makeTransaction, ethBalance } = this.props;
    let { token, slippage } = data;
    let { address, name, pair } = token;
    let { ethReserves, tokenReserves } = pair;
    let amountETH = this.state.ethIn;
    if (amountETH.isZero()) {
      this.log("Bought zero " + name + "! Congratulations.");
      return;
    }
    if (amountETH.gt(ethBalance)) {
      this.log("Trying to spend more than you have.");
      return;
    }
    let deadline = await this.getDeadline();
    const tolerance = ethers.utils
      .parseEther(slippage)
      .mul(tokenReserves)
      .div(ethReserves);
    let { tokenOut } = Utils.getTokenOut(token, amountETH);
    let amountOutMin = tokenOut.lt(tolerance) ? "0" : tokenOut.sub(tolerance);
    this.log("Sending buy transaction.");
    await makeTransaction(
      OptiSwap.optiswap,
      "buyToken",
      [address, amountOutMin, deadline],
      amountETH,
    );
  };

  async _sellToken() {
    let { data, makeTransaction, selectedAddress } = this.props;
    let { token, gasCost, slippage } = data;
    let { address, name, balance } = token;
    let { tokenIn } = this.state;
    let amountToken = tokenIn;

    if (amountToken.isZero()) {
      this.log("Sold zero " + name + "! Congratulations.");
      return;
    }
    if (amountToken.gt(balance)) {
      this.log("Trying to sell more than you have.");
      return;
    }
    let allowance = BigNumber.from(0);
    if (selectedAddress) {
      allowance = await token.contract.allowance(
        selectedAddress,
        optiSwap.address,
      );
    }
    if (amountToken.gt(allowance)) {
      await this._approveToken();
    }
    let deadline = await this.getDeadline();
    const tolerance = ethers.utils.parseEther(slippage);
    let { ethOut } = Utils.getEthOut(token, amountToken, gasCost);
    let amountOutMin = ethOut.lt(tolerance) ? "0" : ethOut.sub(tolerance);
    this.log("Selling " + token.name);
    await makeTransaction(OptiSwap.optiswap, "sellToken", [
      address,
      amountToken,
      amountOutMin,
      deadline,
    ]);
  }

  async _approveToken(contract) {
    let { data, makeTransaction } = this.props;
    let { token } = data;
    let { symbol } = token;
    const amount = ethers.constants.MaxUint256;
    this.log("Approving " + symbol);
    await makeTransaction(token.contract, "approve", [
      optiSwap.address,
      amount,
    ]);
  }

  static totalCostEstimate = (buying, gasPrice) => {
    const estimate = buying ? 120000 : 140000; //gas usage TODO: Update based on hoge exclusions
    const totalCost = estimate * gasPrice; //cost = gas usage * gas price
    // return totalCost
    return BigNumber.from(totalCost.toString().split(".")[0]);
  };

  static startingData = async (
    provider,
    signer,
    routeInfo,
    selectedAddress,
    ethBalance,
    safeGasPrice,
    utils,
  ) => {
    const { getToken } = utils;
    let token = utils.emptyToken;
    const { tokenAddress } = routeInfo;
    if (tokenAddress) {
      token = await getToken(tokenAddress);
    }
    OptiSwap.optiswap = new ethers.Contract(
      optiSwap.address,
      OptiSwapArtifact.abi,
      signer,
    );
    let initialLogs = [
      `Loaded ${token.name} data.`,
      "Welcome to OptiSwap! Please enjoy your stay.",
    ];
    let data = {
      token,
      initialized: true,
      counter: 0,
      logs: initialLogs,
      optiSize: zero,
      gasCost: OptiSwap.totalCostEstimate(true, safeGasPrice),
      gasPrice: safeGasPrice,
      buying: true,
    };
    data = this.optimize(data, utils);
    if (token.slippage) {
      data["slippage"] = token.slippage;
    } else {
      data["slippage"] = ".002";
    }
    return this.updateBalances(
      provider,
      data,
      selectedAddress,
      ethBalance,
      safeGasPrice,
      utils,
    );
  };

  static updateBalances = async (
    provider,
    data,
    selectedAddress,
    ethBalance,
    safeGasPrice,
    utils,
  ) => {
    if (!data || !data.token) {
      console.log("PINEAPPLE 2 NO DATA");
      return data;
    }
    let newLogs = [];
    let { token, counter, buying, gasPrice, logs } = data;
    window.data = data;
    let { symbol, pair } = token; //balance is here too

    let tokenBalance = BigNumber.from(0);
    if (selectedAddress) {
      tokenBalance = await token.contract.balanceOf(selectedAddress);
    }
    // already have ethBalance injected
    const oldEthBalance = ethBalance;
    if (!oldEthBalance.isZero() && !oldEthBalance.eq(ethBalance)) {
      if (oldEthBalance.gt(ethBalance)) {
        newLogs.push(
          "Spent " + Utils.ethDisplay(oldEthBalance.sub(ethBalance)) + " ETH",
        );
      } else {
        newLogs.push(
          "Gained " + Utils.ethDisplay(ethBalance.sub(oldEthBalance)) + " ETH",
        );
      }
    }
    const oldTokenBalance = token.balance;
    if (!oldTokenBalance.isZero() && !oldTokenBalance.eq(tokenBalance)) {
      if (oldTokenBalance.gt(tokenBalance)) {
        newLogs.push(
          "Spent " +
            Utils.tokenDisplay(oldTokenBalance.sub(tokenBalance), token) +
            " " +
            symbol,
        );
      } else {
        newLogs.push(
          "Gained " +
            Utils.tokenDisplay(tokenBalance.sub(oldTokenBalance), token) +
            " " +
            symbol,
        );
      }
    }
    token.balance = tokenBalance;
    data.token = token;
    let oldEthReserves = pair.ethReserves;
    await utils.updateReserves(token.address);
    let updatedToken = await utils.getToken(token.address);
    let { ethReserves } = updatedToken.pair;
    if (!oldEthReserves.isZero() && !oldEthReserves.eq(ethReserves)) {
      if (oldEthReserves.gt(ethReserves)) {
        newLogs.push(
          Utils.ethDisplay(oldEthReserves.sub(ethReserves)) +
            " ETH sell occured",
        );
      } else {
        newLogs.push(
          Utils.ethDisplay(ethReserves.sub(oldEthReserves)) +
            " ETH buy occured",
        );
      }
    } else {
    }
    try {
      fetch(
        "https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=4493VQY6KMG7CK7K37UYZP4ASA22RBD2AN",
      ).then(async (response) => {
        const res = await response.json();
        if (res.result.SafeGasPrice) {
          const estimate = buying ? 170000 : 190000;
          const totalCost = estimate * gasPrice;
          data.gasCost = ethers.utils.parseUnits(totalCost.toString(), "gwei");
        }
      });
    } catch (error) {
      console.log("Gas oracle hiccuped.");
    }
    data = this.optimize(data, utils);
    data.counter = (counter + 1) % 4;
    data.logs = this.concatLogs(logs.concat([newLogs.join(", ")]));
    return data;
  };

  static handleConnectedWallet = async (data, utils) => {
    let { token } = data;
    token = await utils.getToken(token.address, true);
    return { ...data, token };
  };

  render() {
    const {
      data,
      selectedAddress,
      connectWallet,
      ethBalance,
      setData,
      tokenSelectionRouteCallback,
      utils,
    } = this.props;
    if (!data || !data.token) {
      // console.log(JSON.stringify(data))
      return <div />;
    }
    let { buying, token, optiSize, optiPercent, initialized, gasCost, logs } =
      data;

    let { symbol, decimals } = token;
    const { ethIn, tokenIn } = this.state;
    const isWalletConnected = typeof selectedAddress !== "undefined";

    const tokenBalance = Utils.tokenDisplay(token.balance, token);
    const ethBalanceDisplay = Utils.ethDisplay(ethBalance);
    let tokenOut, ethOut, orderRealizePercent;
    if (buying) {
      let result = Utils.getTokenOut(token, ethIn, gasCost);
      orderRealizePercent = result.orderRealizePercent;
      tokenOut = Utils.tokenDisplay(result.tokenOut, token);
    } else {
      let result = Utils.getEthOut(token, tokenIn, gasCost);
      ethOut = Utils.ethDisplay(result.ethOut);
      orderRealizePercent = result.orderRealizePercent;
    }
    optiSize = buying
      ? Utils.ethDisplay(optiSize)
      : Utils.tokenDisplay(optiSize, token);

    const orderRealizePercentRoundedDown =
      orderRealizePercent > optiPercent ? optiPercent : orderRealizePercent;
    // realization ratio of actual realization relative to optimal realization
    const realizationRatio =
      orderRealizePercentRoundedDown / parseFloat(optiPercent);

    initialized = true;

    return (
      <div className="md:w-full justify-center">
        {/* Token Select */}
        <div className="md:flex flex-row md:justify-center w-full items-center text-white font-bold md:p-10 ">
          <div className="flex justify-center">
            <img
              alt="logo"
              className="w-24 h-24 md:w-10 md:h-10"
              src={token.logo}
            />
          </div>
          <span className="p-2">The UniSwap solution for</span>
          <div className="md:w-1/3 p-2">
            <TokenSelect
              token={token}
              selectionCallback={async (value) => {
                await tokenSelectionRouteCallback(
                  `#swap/${value.address}`,
                  value.address,
                );
                console.log(data);
              }}
            />
          </div>
        </div>

        <div className="flex flex-col items-center">
          {/* Main Panel */}
          <div
            name="mainPanel"
            className="relative flex flex-col md:flex-row justify-center p-3 gap-4 text-white w-[95%] max-w-3xl md:w-[90%] bg-slate-900 rounded-3xl"
          >
            {/* Left Panel */}
            <div
              name="left-panel"
              className="relative flex flex-col items-center justify-between order-last md:order-first md:w-1/2 gap-2 font-poppins"
            >
              <div className="flex flex-col flex-grow p-4 w-full items-center justify-between shadow-[0_0_0_1px_#555_inset] rounded-xl text-sm">
                <h1 className="p-1 text-2xl">Order Analysis</h1>
                <div className="w-full h-28 flex flex-col justify-evenly">
                  <div className="flex flex-col justify-evenly w-full">
                    <div className="flex justify-between">
                      <b className="text-gray-300">Your order realizes</b>
                      <b
                        className={
                          realizationRatio === 1
                            ? "text-sky-400"
                            : realizationRatio === 0
                              ? "text-gray-200"
                              : realizationRatio >= 0.95
                                ? "text-green-400"
                                : realizationRatio >= 0.9
                                  ? "text-yellow-400"
                                  : "text-orange-400"
                        }
                      >
                        {orderRealizePercentRoundedDown}%
                      </b>
                    </div>
                  </div>
                  <div className="flex flex-col justify-evenly w-full">
                    <div className="flex justify-between">
                      <b className="text-gray-300">Optimal Size</b>
                      <b className="flex">
                        <span className="max-w-[84px] overflow-hidden text-ellipsis text-right">
                          {optiSize}
                        </span>
                        &nbsp;
                        {buying ? "ETH" : symbol}
                      </b>
                    </div>
                  </div>
                  <div className="flex flex-col justify-evenly w-full">
                    <div className="flex justify-between">
                      <b className="text-gray-300">Optimal Realization</b>
                      <b>{optiPercent}%</b>
                    </div>
                  </div>
                  <div className="flex flex-col justify-evenly w-full text-gray-400">
                    <div className="flex justify-between">
                      <span>Gas cost estimate</span>
                      <span>{Utils.ethDisplay(gasCost)} ETH</span>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="flex items-center p-2.5 w-full justify-center text-lg cursor-pointer rounded-xl bg-indigo-700 hover:saturate-150 transition duration-175"
                onClick={() => this._useOptisize()}
              >
                Use optimal size
              </button>
            </div>

            {/* Right Panel */}
            <div
              name="right-panel"
              className="flex flex-col gap-2 items-center justify-between md:w-1/2"
            >
              <div className="relative flex flex-col items-center justify-center w-full">
                {/* "From" Form */}
                <div className="flex flex-col mb-1 p-3 w-full bg-slate-bg rounded-xl focus-within:shadow-[0_0_0_1px_#aaa_inset]">
                  <div className="flex justify-between">
                    <span className="text-slate-400 text-sm">From</span>
                    <span className="text-slate-400 text-sm">
                      Balance:{" "}
                      {isWalletConnected
                        ? buying
                          ? `${ethBalanceDisplay} ETH`
                          : `${tokenBalance} ${token.symbol}`
                        : "N/A"}
                    </span>
                  </div>
                  <div className="flex items-center justify-between mb-2">
                    <input
                      className="py-1 w-[70%] h-12 text-3xl font-semibold bg-transparent focus:outline-none"
                      type="number"
                      step="any"
                      name={!buying ? "tokenIn" : "ethIn"}
                      required
                      value={
                        !buying ? this.state.tokenInput : this.state.ethInput
                      }
                      disabled={!initialized}
                      placeholder="0"
                      // prevent user from entering e/E for scientific notation
                      onKeyDown={(e) =>
                        (e.key.toLowerCase() === "e" || e.key === "-") &&
                        e.preventDefault()
                      }
                      onChange={(event) => {
                        const val = event.target.value;
                        if (val === "e") {
                          return;
                        }
                        // stringify empty string to '0' for bn_val
                        let stringifiedVal = val;
                        if (stringifiedVal === "") {
                          stringifiedVal = "0";
                        }
                        let bn_val = !buying
                          ? ethers.utils.parseUnits(stringifiedVal, decimals)
                          : ethers.utils.parseEther(stringifiedVal);
                        this.setState(
                          !buying
                            ? { tokenIn: bn_val, tokenInput: val }
                            : { ethIn: bn_val, ethInput: val },
                        );
                      }}
                    />
                    <div className="w-1/4 text-right p-1 text-xl">
                      {buying ? "ETH" : token.symbol}
                    </div>
                  </div>
                </div>
                {/* Swap Tokens Button */}
                <button
                  className="absolute p-1 bg-zinc-700 rounded rotate-90 shadow-[0_0_8px_1px_#0006]"
                  onClick={() => {
                    this._resetAmounts();
                    data.buying = !data.buying;
                    setData(OptiSwap.optimize(data, utils));
                    this.setState({
                      ethInput: "",
                      tokenInput: "",
                      ethIn: zero,
                      tokenIn: zero,
                    });
                  }}
                >
                  <SVG src="icons/rotate.svg" />
                </button>
                {/* "To" Output */}
                <div className="flex flex-col justify-between p-3 w-full bg-slate-bg rounded-xl">
                  <div className="flex justify-between">
                    <span className="text-slate-400 text-sm">To</span>
                    <span className="text-slate-400 text-sm">
                      Balance:{" "}
                      {isWalletConnected
                        ? buying
                          ? `${tokenBalance} ${token.symbol}`
                          : `${ethBalanceDisplay} ETH`
                        : "N/A"}
                    </span>
                  </div>
                  <div className="flex items-center justify-between mb-2">
                    <input
                      className="py-1 w-[70%] h-12 text-3xl font-semibold bg-transparent focus:outline-none opacity-100"
                      value={buying ? tokenOut : ethOut}
                      disabled
                      placeholder="0"
                    />
                    <div className="w-1/4 text-right p-1 text-xl">
                      {buying ? token.symbol : "ETH"}
                    </div>
                  </div>
                </div>
              </div>
              {/* Trade Button */}
              <button
                className="flex items-center p-2.5 w-full justify-center text-lg cursor-pointer rounded-xl bg-gradient-to-l from-indigo-800 to-sky-600 hover:saturate-150 transition duration-175"
                type="submit"
                disabled={!initialized}
                onClick={
                  isWalletConnected
                    ? buying
                      ? () => this._buyToken()
                      : () => this._sellToken()
                    : () => connectWallet()
                }
              >
                {isWalletConnected
                  ? `${buying ? "BUY" : "SELL"} ${symbol}`
                  : "Connect Wallet"}
              </button>
            </div>
          </div>

          {/* Logger */}
          <div className="flex justify-center w-full h-full">
            {logs.length > 0 && (
              <div className="md:w-1/2 justify-center md:1/6">
                <div
                  name="logger"
                  className="w-full h-auto mt-5 break-words font-mono border-2 border-gray-500 text-left bg-black text-white"
                >
                  {logs.map((logRow, index) => {
                    return <div key={`log${index}`}>{logRow}</div>;
                  })}
                </div>
              </div>
            )}
          </div>

          {/* Settings */}
          {this.state.displaySettings && (
            <div className="justify-center items-center flex rounded-r-xl border-2 border-slate-700 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto border-2 border-slate-700 max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-slate-200 outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold">SETTINGS</h3>
                  </div>
                  <div className="relative p-6 flex-auto">
                    <div className="w-64 flex grid-cols-2 text-sm">
                      If your slippage tolerance is less than the transaction
                      cost for a bot to buy and sell, you won't be worth
                      sandwiching. There probably isn't a good reason to
                      increase it! Come talk about it on Discord. Change at your
                      own risk.
                    </div>
                    <div className="w-full flex grid-cols-2 text-sm">
                      <div className="w-1/4 p-1">Slippage: </div>
                      <div className="w-1/2 flex grid-cols-2 gap-0 align-middle">
                        <input
                          className="text-right p-1 rounded w-1/2 focus:outline-none"
                          type="number"
                          step="any"
                          name="tokenIn"
                          required
                          value={data.slippage}
                          disabled={!initialized}
                          placeholder="0"
                          onChange={(event) => {
                            let val = event.target.value;
                            setData({ ...data, slippage: val });
                          }}
                        />
                        <div className="w-1/4 text-right p-1">ETH</div>
                      </div>
                    </div>
                    <div className="w-full flex grid-cols-2 text-sm">
                      <div className="w-1/4 p-1">Deadline: </div>
                      <div className="w-1/2 flex grid-cols-2 gap-0 align-middle">
                        <input
                          className="text-right p-1 rounded w-1/2 focus:outline-none"
                          type="number"
                          step="any"
                          name="tokenIn"
                          required
                          value={this.state.deadline}
                          disabled={!initialized}
                          placeholder="0"
                          onChange={(event) => {
                            let val = event.target.value;
                            this.setState({ deadline: val });
                          }}
                        />
                        <div className="w-1/4 text-right p-1">minutes</div>
                      </div>
                    </div>
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => {
                          this.setState({ displaySettings: false });
                        }}
                      >
                        SAVE AND CLOSE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="md:absolute left-10 bottom-10 font-bold justify-center h-2 text-white">
            {initialized && !this.state.displaySettings && (
              <div className="text-center cursor-pointer text-xs">
                <div
                  className="underline"
                  onClick={() => this.setState({ displaySettings: true })}
                >
                  SETTINGS
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default OptiSwap;
