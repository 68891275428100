import React from "react";

export default class Button extends React.Component {
  render() {
    const { buttonText, onClick, type, disabled = false } = this.props;
    if (type === "off") {
      return null;
    }

    let additional, icon;
    if (type === "warn") {
      additional = "warn";
    } else if (type === "selection") {
      additional = "selection";
    } else if (type === "delayed") {
      additional = "delayed";
    } else if (type === "proceed") {
      additional = "proceed";
    } else if (type === "pool") {
      additional = "pool";
    } else if (type === "info") {
      additional = "border-2";
      icon = "icon-lightbulb-alt.svg";
    }
    let className = `items-center w-full justify-center flex text-sm text-white font-bold bg-black-400 hover:bg-black-600 rounded px-4 py-3 button1 ${additional}`;
    return (
      <button
        onClick={onClick}
        type="button"
        className={className}
        disabled={disabled}
      >
        {icon && <img className={"px-1 h-5"} alt="" src={icon} />}
        {buttonText}
      </button>
    );
  }
}
