import React from "react";
import ConnectedWallet from "./ConnectedWallet";
import { Info } from "./Info";
import { TransactionStatus } from "./TransactionStatus";
import "bootstrap/dist/css/bootstrap.css";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      showInfo: false,
    };
    this.state = this.initialState;
  }

  render() {
    const {
      transactionError,
      txBeingSent,
      initialized,
      selectedAddress,
      networkError,
      ethBalance,
      connectWallet,
      dismissNetworkError,
      dismissTransactionError,
      goHome,
    } = this.props;

    let { showInfo } = this.state;
    return (
      <div className="md:w-2/3 p-5 text-white ">
        <div>
          <div className="">
            <img
              className="md:absolute md:left-20 w-80 cursor-pointer"
              onClick={() => {
                goHome();
              }}
              src={"Logo-Optiswap-Pro-Horizontal-KO.svg"}
              alt="Info"
            />
          </div>
        </div>
        <div className="md:absolute right-8 top-10">
          <div className="text-sm flex p-2 items-center gap-2 text-center">
            <div className="w-8 h-8">
              <img
                className="cursor-pointer"
                onClick={() => {
                  this.setState({ showInfo: !this.state.showInfo });
                }}
                src={"info.png"}
                alt="Info"
              />
            </div>
            <ConnectedWallet
              initialized={initialized}
              selectedAddress={selectedAddress}
              networkError={networkError}
              ethBalance={ethBalance}
              connectWallet={connectWallet}
              dismissNetworkError={dismissNetworkError}
              dismissTransactionError={dismissTransactionError}
            />
          </div>

          <TransactionStatus
            transactionError={transactionError}
            txBeingSent={txBeingSent}
          />
        </div>
        {showInfo && (
          <Info
            className="absolute"
            close={() => {
              this.setState({ showInfo: false });
            }}
          />
        )}
      </div>
    );
  }
}

export default Dashboard;
