import React from "react";
import Utils from "../Utils";

export function TransactionStatus({ txBeingSent, transactionError }) {
  let text1 = "";
  let displayTx = "";
  if (transactionError) {
    console.log(transactionError);
    text1 = Utils.formatTransactionError(transactionError);
  } else if (txBeingSent) {
    displayTx = Utils.addressShortened(txBeingSent);
  }

  return (
    <div className="p-2">
      <div>{text1}</div>
      {txBeingSent && (
        <div>
          {" "}
          Waiting for transaction <br />
          <a
            className="underline"
            target="_blank"
            rel="noreferrer"
            href={`https://etherscan.io/tx/` + txBeingSent}
          >
            {displayTx}
          </a>
        </div>
      )}
    </div>
  );
}
