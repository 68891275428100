import React from "react";
import HomeCardTitle from "./HomeCardTitle";

export default function HomeCardLinks() {
  return (
    <div className="flex flex-col">
      <HomeCardTitle title="Community" icon="icon-message-square-dots.svg" />
      <div className="p-3">
        Stay in the loop: Join our social channels and say hello. Get a{" "}
        <a
          className="text-green-500 underline"
          href={"https://www.redbubble.com/people/Hoge2-0/shop"}
        >
          shirt.
        </a>
      </div>
      <div className="p-3">
        Contact us by{" "}
        <a
          className="text-green-500 underline"
          href="mailto: contact@optiswap.pro"
        >
          email.
        </a>
      </div>
      <div className="flex justify-around">
        <a className="col w-14 h-14" href={"https://discord.gg/rn5BVHG4t3"}>
          <img
            src={"sm-discord-icon.svg"}
            alt={"discord"}
            width="64"
            height="64"
          />
        </a>
        <a
          className="col w-14 h-14"
          href={"https://www.youtube.com/channel/UCtlyzOmO1_2R6usd6tR4xfQ"}
        >
          <img
            src={"sm-youtube-icon.svg"}
            alt={"youtube"}
            width="64"
            height="64"
          />
        </a>
        <a className="col w-14 h-14" href={"https://twitter.com/OptiSwap"}>
          <img
            src={"sm-twitter-icon.svg"}
            alt={"twitter"}
            width="64"
            height="64"
          />
        </a>
      </div>
    </div>
  );
}
